     import React from 'react';

import Layout from '../components/layout';
import Slider from '../components/slider';
import { Box, Text, Stack, _hover, Flex } from '@chakra-ui/core';
import PageWrapper from '../components/page.wrapper';

import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaGithub,
    FaEnvelope,
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaInstagram,
} from 'react-icons/fa';

const img = require('../images/innerbanner.jpg');
const imgNew = require('../images/carehome/Health-checkup1.jpg');


const  HealthCheckupOnsite = () => (
    <Layout>
        <Box className="sub-menu">
            <Box className="sub-banner" style={{ backgroundImage: `url(${img})` }} />
            <Box className="content-row">
				<h1>Health CheckUp OnSite</h1>
                <Flex align="center" className="flex-row">
                    <Box flex="1" p="4">
                        <Box as="img" src={imgNew} w="100%"/>
						 
                    </Box>

                    <Box flex="1" p="4" className="brief-para">
                        <p>
                            Good health isn’t simply the absence of disease but the complete physical, mental , and social well-being of a person, this is a belief which is reflected in our predictive approach to healthcare. With stress and an unhealthy environment being commonplace today , lifestyle disease are steadily on the rise . Our comprehensive , ultramodern diagnostic service monitor your health early on , while our natural healing practices help in healing through healthy lifestyle changes , thus keeping you and your loved once protected from the risk of ailments.<br /><br />
We recognize the lifestyle of employees and prevent ailments for corporate companies, Pharma, chemical and Food industries / Established etc..... Our efforts aim at offering Health care solutions at on site office of the  company/Industries premises. 

                        </p> 
						 
                    </Box>
                </Flex>
            </Box>
        </Box>
    </Layout>
);

export default HealthCheckupOnsite;